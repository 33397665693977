import React, { useState, useEffect } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ButtonCustom from '../../../common/components/ui/button/button';
import TextField from '@material-ui/core/TextField';
import { getPendingSupplier } from '../../../store/actions/admin';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import MaterialTable from 'material-table';

const UserAccounts = (props) => {
    const [pendingSupplerList, setPendingSupplerList] = useState([]);
    const [pendingSupplerResult, setpendingSupplerResult] = useState([]);
    const [searchParam, setSearchParam] = useState({
        name: '',
        email: '',
    });
   
    const searchResult = () => {
        let list = [...pendingSupplerResult];
        if (searchParam.email) {
            const email = searchParam.email.toLowerCase();
            list = list.filter(item => item.email.toLowerCase().indexOf(email) > -1);
        } if (searchParam.name) {
            const name = searchParam.name.toLowerCase();
            list = list.filter(item => item.fullName.toLowerCase().indexOf(name) > -1);
        }
        setPendingSupplerList(list);
    }

    const resetFields = () => {
        setSearchParam({
            name: '',
            email: ''
        });
        setPendingSupplerList(pendingSupplerResult);
    }
    const onSearchHandler = id => (event) => {
        if (event) event.preventDefault();
        setSearchParam({
            ...searchParam,
            [id]: event.target.value
        })
    }

    const handleKeyPress = id => (event) => {
        if(event.key === 'Enter') {
            searchResult();
        }
    }

    const getPendingSupplierList = () => {
        props.getPendingSupplierStart();
        getPendingSupplier().then(response => {
            setPendingSupplerList(response.data);
            setpendingSupplerResult(response.data);
            props.getPendingSupplierSuccess(response.data);
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getPendingSupplierFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    useEffect(() => {
        getPendingSupplierList();
    }, []);

    const renderRows = () => {
        const list = pendingSupplerList || [];
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Full Name', field: 'fullName' },
                        { title: 'Email', field: 'email' },
                    ]}
                    data={list}
                    onRowClick={(event, rowData) => { props.history.push('/admin/userAccountDetails/' + rowData.id, { rowData }) }}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                />
            </React.Fragment>
        );
    }
    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Approve User Accounts</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row>
                        <Col sm={9}>
                            <Row>
                                <Col>
                                    <TextField id="name" label="Name"
                                        variant="outlined" value={searchParam.name || ''}
                                        onChange={onSearchHandler('name')}
                                        onKeyPress={handleKeyPress('name')}
                                    />
                                </Col>
                                <Col>
                                    <TextField id="email" label="Email"
                                        variant="outlined" value={searchParam.email || ''}
                                        onChange={onSearchHandler('email')}
                                        onKeyPress={handleKeyPress('name')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} >
                            <ButtonCustom className='button primary' variant="contained" onClick={searchResult}>Search</ButtonCustom>
                            <ButtonCustom className='button secondary' variant="contained" onClick={resetFields}>Reset</ButtonCustom>
                        </Col>
                        <Col>
                            {renderRows()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPendingSupplierStart: () => dispatch(actions.getPendingSupplierStart()),
        getPendingSupplierSuccess: (data) => dispatch(actions.getPendingSupplierSuccess(data)),
        getPendingSupplierFail: () => dispatch(actions.getPendingSupplierFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccounts);
