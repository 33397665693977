import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ButtonCustom from '../../../common/components/ui/button/button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { connect, useSelector } from 'react-redux';
import { STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED } from '../../../common/constants';
import MaterialTable from 'material-table';
import { formatDate } from './../../../common/utils/utility';

const ItemRequests = (props) => {
    const [searchData, setsearchData] = useState({
        name: '',
        displayName: '',
        storeDisplayName: '',
        date: null,
        requestedBy: '',
        status: STATUS_PENDING
    });

    const handleSearchDataChange = id => (event) => {
        setsearchData({
            ...searchData,
            [id]: event.target.value
        })
    }
    const handleKeyPress = id => (event) => {
        if (event.key === 'Enter') {
            onSearch();
        }
    }
    const handleDateChange = (date) => {
        setsearchData({
            ...searchData,
            date: date
        })
    };

    const allProducts = useSelector(state => state.admin.allProducts);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setProducts(onSearch);
    }, [allProducts]);

    const onSearch = () => {
        setProducts(allProducts.filter(item => {
            return (searchData.name === '' || item.itemName.toLowerCase().includes(searchData.name.toLowerCase())) && (searchData.displayName === '' || item.displayName.toLowerCase().includes(searchData.displayName.toLowerCase())) &&
                (searchData.storeDisplayName === '' || item.storeDisplayName.toLowerCase().includes(searchData.storeDisplayName.toLowerCase())) && (searchData.status === '' || item.status === searchData.status) &&
                (searchData.requestedBy === '' || item.createdBy.toLowerCase().includes(searchData.requestedBy.toLowerCase())) && (!searchData.date || (new Date(item.createdTimestamp)).toLocaleDateString('en-US').includes(searchData.date.toLocaleDateString('en-US')));
        }));
    }

    const onReset = () => {
        setsearchData({
            name: '',
            displayName: '',
            storeDisplayName: '',
            date: null,
            requestedBy: '',
            status: ''
        });
        setProducts(allProducts);
    }

    const renderRows = () => {
        const list = products || [];
        list.sort((a, b) => (b.createdTimestamp > a.createdTimestamp ? 1 : -1));
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Number', field: 'itemName' },
                        { title: 'Product Name', field: 'displayName' },
                        {
                            title: 'Requested On', field: 'createdTimestamp',
                            render: row => <span>{formatDate(row["createdTimestamp"])}</span>
                        },
                        { title: 'Requested By', field: 'createdBy' },
                        { title: 'Supplier Name', field: 'supplierName' },
                        { title: 'Status', field: 'status' },

                    ]}
                    data={list}
                    onRowClick={(event, rowData) => { props.history.push('/admin/pendingRequestDetails/' + rowData.productRequestId, rowData) }}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                />
            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Change Requests</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row>
                        <Col sm={12} md={9} lg={9}>
                            <form noValidate autoComplete="off">
                                <Row>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="name" label="Item"
                                            variant="outlined" value={searchData.name || ''}
                                            onChange={handleSearchDataChange('name')}
                                            onKeyPress={handleKeyPress('name')}
                                        />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="displayName" label="Display Name"
                                            variant="outlined" value={searchData.displayName || ''}
                                            onChange={handleSearchDataChange('displayName')}
                                            onKeyPress={handleKeyPress('displayName')}
                                        />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="storeDisplayName" label="Store display name"
                                            variant="outlined" value={searchData.storeDisplayName || ''}
                                            onChange={handleSearchDataChange('storeDisplayName')}
                                            onKeyPress={handleKeyPress('storeDisplayName')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4} lg={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker disableToolbar variant="inline" inputVariant="outlined"
                                                    format="MM/dd/yyyy" id="date"
                                                    label="Requested on"
                                                    value={searchData.date} onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                    className="full-width"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <TextField id="requestedBy" label="Requested by"
                                            variant="outlined" value={searchData.requestedBy || ''}
                                            onChange={handleSearchDataChange('requestedBy')}
                                            onKeyPress={handleKeyPress('requestedBy')}
                                        />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <FormControl variant="outlined">
                                            <InputLabel>Status</InputLabel>
                                            <Select id="status" value={searchData.status}
                                                onChange={handleSearchDataChange('status')} label="Status">
                                                <MenuItem value={STATUS_PENDING}>{STATUS_PENDING}</MenuItem>
                                                <MenuItem value={STATUS_APPROVED}>{STATUS_APPROVED}</MenuItem>
                                                <MenuItem value={STATUS_REJECTED}>{STATUS_REJECTED}</MenuItem>
                                                <MenuItem value="">NONE</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                        <Col sm={12} md={3} lg={3} >
                            <ButtonCustom id='searchId' className='button primary' variant="contained" onClick={onSearch}>Search</ButtonCustom>
                            <ButtonCustom id='searchReset' className='button secondary' variant="contained" onClick={onReset}>Reset</ButtonCustom>
                        </Col>
                        <Col>
                            {renderRows()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemRequests);